// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: $l-break) {
        font-size: 1.5625vw;
    }
}

.header_logo {
    & {
        max-width: remify(290, 16);
    }
}

.header_menu-list_container {
    .menu-list.-navigation {
        transform: translateX(#{remify(25, 20)});
    }

    @media screen and (max-width: $l-break) {
        .menu-list.-navigation .menu-list_link {
            padding-left: remify(20, 20);
            padding-right: remify(20, 20);
        }
    }
}
