// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_inner {
    & {
        padding: remify(($content-padding * 1.25) ($content-padding * 0.8333333333333333));
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        padding: remify(($content-padding * 1.25) ($content-padding * 0.8333333333333333));
    }

    > .content_inner {
        padding: 0;
    }
}
