// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_swiper-container {
    .swiper-video_spacer {
        height: remify(580, 16);
    }
}
