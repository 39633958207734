// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #37369B;
$primary_alt: #26255E;

$accent: #F39D13;
$accent_alt: #D88600;

$light: #FFFFFF;
$light_alt: #CDCDCD;

$dark: #000000;
$dark_alt: #222222;

$foreground: #000000;
$foreground_alt: #222222;

$background: #FFFFFF;
$background_alt: #CDCDCD;

$page_background: #131243;
$page_background_alt: #26255E;

$warning: #9F0000;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
$body-font: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1440;
$column-gap: 40;
$content-padding: 60;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 6,
    navigation: 5,
    hero: 4,
    banner: 3,
    content: 2,
    footer: 1,
);
