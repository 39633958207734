// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: $xl-break) {
        font-size: 1.25vw;
    }
}

.header_menu-list_container {
    @media screen and (max-width: $xxl-break) {
        .menu-list.-navigation {
            transform: translateX(#{remify(35, 20)});
        }

        .menu-list.-navigation .menu-list_link {
            padding-left: remify(35, 20);
            padding-right: remify(35, 20);
        }
    }
}
