// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_swiper-container {
    .swiper-video_spacer {
        height: remify(470, 16);
    }

    .swiper-video_container {
        height: remify(470, 16);
        width: remify(835, 16);
    }

    .swiper-video_container {
        @media screen and (min-width: remify(835, 16)) {
            height: auto;
            left: 0;
            padding-bottom: 56.25%;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }
}
