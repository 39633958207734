// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callouts
\* ------------------------------------------------------------------------ */

.callouts_inner {
    & {
        padding-bottom: remify($content-padding * 1.5);
        padding-top: remify($content-padding * 1.5);
    }
}

.callouts_row {
    @media screen and (max-width: $xxl-break) {
        font-size: 1.171303074670571vw;
    }
}

/* fullbleed variant */

.callouts-block.-fullbleed {
    & {
        padding-bottom: remify($content-padding * 1.5);
        padding-top: remify($content-padding * 1.5);
    }

    > .callouts_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
