/* ------------------------------------------------------------------------ * * Helpers
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Colors
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Functions
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Mixins
\* ------------------------------------------------------------------------ */
._dark {
  color: #000000 !important; }

._light {
  color: #FFFFFF !important; }

._big {
  font-size: 1.25em !important; }

._small {
  font-size: 0.75em !important; }

._bold {
  font-weight: 700 !important; }

._italic {
  font-style: italic !important; }

._textcenter {
  text-align: center !important; }

._textleft {
  text-align: left !important; }

._textright {
  text-align: right !important; }

._nowrap {
  white-space: nowrap !important; }

._center {
  margin-left: auto !important;
  margin-right: auto !important; }

._left {
  float: left !important; }

._right {
  float: right !important; }

._nomargin {
  margin: 0 !important; }

._nopadding {
  padding: 0 !important; }

._notransform {
  transform: none !important; }

._notransition {
  transition: none !important; }

._block {
  display: block !important; }

._flex {
  -js-display: flex !important;
  display: flex !important; }

._visuallyhidden {
  background: #f1f1f1 !important;
  border-radius: 0 !important;
  box-shadow: 0em 0em 0.14286em 0.14286em rgba(0, 0, 0, 0.6) !important;
  color: #F39D13 !important;
  display: block !important;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif !important;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  height: auto !important;
  left: -999em !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  outline: none !important;
  padding: 1.07143em 1.64286em 1em !important;
  position: absolute;
  text-decoration: none !important;
  text-shadow: none !important;
  text-transform: none !important;
  top: -999em !important;
  white-space: nowrap;
  width: auto !important; }

._visuallyhidden:focus {
  left: 0.42857em !important;
  top: 0.42857em !important;
  z-index: 100000 !important; }

._primary {
  color: #37369B !important; }

._accent {
  color: #F39D13 !important; }

._warning {
  color: #9F0000 !important; }

._sticky {
  position: sticky !important;
  top: 0 !important; }

@media screen and (min-width: 601px) {
  @supports (position: -webkit-sticky) or (position: sticky) {
    .admin-bar ._sticky {
      top: 46px !important; } } }

@media screen and (min-width: 783px) {
  @supports (position: -webkit-sticky) or (position: sticky) {
    .admin-bar ._sticky {
      top: 32px !important; } } }

/* visibility classes */
@media (pointer: fine) {
  ._touch {
    display: none !important; } }

@supports not (pointer: fine) {
  ._touch {
    display: none !important; } }

@media (pointer: course) {
  ._mouse {
    display: none !important; } }

@media print {
  ._noprint {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  ._tablet:not(._phone),
  ._notebook:not(._phone),
  ._desktop:not(._phone) {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  ._phone:not(._tablet),
  ._notebook:not(._tablet),
  ._desktop:not(._tablet) {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 79.9375em) {
  ._phone:not(._notebook),
  ._tablet:not(._notebook),
  ._desktop:not(._notebook) {
    display: none !important; } }

@media screen and (min-width: 80em) {
  ._phone:not(._desktop),
  ._tablet:not(._desktop),
  ._notebook:not(._desktop) {
    display: none !important; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Hero
\* ------------------------------------------------------------------------ */
.hero_swiper-container .swiper-video_spacer {
  height: 29.375em; }

.hero_swiper-container .swiper-video_container {
  height: 29.375em;
  width: 52.1875em; }

@media screen and (min-width: 52.1875em) {
  .hero_swiper-container .swiper-video_container {
    height: auto;
    left: 0;
    padding-bottom: 56.25%;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Callout
\* ------------------------------------------------------------------------ */
.callout {
  min-height: 290px;
  min-height: 18.125rem; }

/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
@media screen and (max-width: 64em) {
  .header-block {
    font-size: 1.5625vw; } }

.header_logo {
  max-width: 18.125em; }

.header_menu-list_container .menu-list.-navigation {
  transform: translateX(1.25em); }

@media screen and (max-width: 64em) {
  .header_menu-list_container .menu-list.-navigation .menu-list_link {
    padding-left: 1em;
    padding-right: 1em; } }

/* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
.content_inner {
  padding: 75px 50px;
  padding: 4.6875rem 3.125rem; }

/* fullbleed variant */
.content-block.-fullbleed {
  padding: 75px 50px;
  padding: 4.6875rem 3.125rem; }

.content-block.-fullbleed > .content_inner {
  padding: 0; }

/* ------------------------------------------------------------------------ * * Callouts
\* ------------------------------------------------------------------------ */
.callouts_inner {
  padding-bottom: 90px;
  padding-bottom: 5.625rem;
  padding-top: 90px;
  padding-top: 5.625rem; }

@media screen and (max-width: 85.375em) {
  .callouts_row {
    font-size: 1.171303074670571vw; } }

/* fullbleed variant */
.callouts-block.-fullbleed {
  padding-bottom: 90px;
  padding-bottom: 5.625rem;
  padding-top: 90px;
  padding-top: 5.625rem; }

.callouts-block.-fullbleed > .callouts_inner {
  padding-bottom: 0;
  padding-top: 0; }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
@media screen and (max-width: 80em) {
  .header-block {
    font-size: 1.25vw; } }

@media screen and (max-width: 85.375em) {
  .header_menu-list_container .menu-list.-navigation {
    transform: translateX(1.75em); }
  .header_menu-list_container .menu-list.-navigation .menu-list_link {
    padding-left: 1.75em;
    padding-right: 1.75em; } }

/* ------------------------------------------------------------------------ * * Hero
\* ------------------------------------------------------------------------ */
.hero_swiper-container .swiper-video_spacer {
  height: 36.25em; }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
